import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import ScreenShot1 from '../images/overview/1.jpg'
import ScreenShot2 from '../images/overview/2.jpg'
import ScreenShot3 from '../images/overview/3.jpg'
import ScreenShot4 from '../images/overview/4.jpg'
import ScreenShot5 from '../images/overview/5.jpg'
import ScreenShot6 from '../images/overview/6.jpg'
import ScreenShot7 from '../images/overview/7.jpg'
import ScreenShot8 from '../images/overview/8.jpg'

const slides = [
    {
        id: 1,
        image: ScreenShot1,
        mainText: 'A Platform  <br />  <span>For Performance</span>',
        subText: 'Constant focus on feedback will enable a culture for performance.  and customer delight. On the ball. ',
        action: {
            'link': '/'
        }
    },
    {
        id: 2,
        image: ScreenShot2,
        mainText: 'A Platform  <br />  <span>For Performance</span>',
        subText: 'Constant focus on feedback will enable a culture for performance.  and customer delight. On the ball. ',
        action: {
            'link': '/'
        }
    },
    {
        id: 3,
        image: ScreenShot3,
        mainText: 'A Platform  <br />  <span>For Performance</span>',
        subText: 'Constant focus on feedback will enable a culture for performance.  and customer delight. On the ball. ',
        action: {
            'link': '/'
        }
    },
    {
        id: 4,
        image: ScreenShot4,
        mainText: 'From <br /><span> The Horses Mouth</span>',
        subText: 'Get raw feedback direct from the source on your performance.This will enable you to get a first hand understanding on the ground situation',
        action: {
            'link': '/'
        }
    },
    {
        id:5,
        image: ScreenShot5,
        mainText: 'Real-time   <br /> <span>Monitoring / Action</span>',
        subText: 'Dashboard provides you with real-time stats for prompt corrective action. KPIs and parameters are tracked for deviations.',
        action: {
            'link': '/'
        }
    },
    {
        id: 6,
        image: ScreenShot6,
    
        mainText: 'Bespoke<span> Surveys</span>',
        subText: 'You design and execute your surveys.Pulse is fully tailored to your specific needs.'
    },
    {
        id:7,
        image: ScreenShot7,
        mainText: 'Access From      <br /> <span>Any Device</span>',
        subText: 'Mobile - Tab - Laptop - Desktop. Access from any Internet Enabled Platform. No client end software is required',
        action: {
            'link': '/'
        }
    },
    {
        id:8,
        image: ScreenShot8,
        mainText: 'Access From      <br /> <span>Any Device</span>',
        subText: 'Mobile - Tab - Laptop - Desktop. Access from any Internet Enabled Platform. No client end software is required',
        action: {
            'link': '/'
        }
    }
]

const HomeAbout = () => {

    return (
        <div>

        <section data-scrollax-parent="true" id="overview">
            <div className="section-subtitle"  data-scrollax="properties: { translateY: '-250px' }" >Overview<span>//</span></div>
                <div className="container">
                <div className="row">
                    
                    <div className="col-md-12">
                        <div className="main-about fl-wrap">
                           
                            <h2>Overview</h2>
                            <p>Sentinel provides a unified platform to enable you to real-time track all your infrastructure from a single touchpoint.</p>
                            <p>All types of corporate assets can be integrated and tracked from IT Hardware & Software, Factory Machinery, Sensors, Cloud Assets, etc.</p>
                            <p>Sentinel enables you to transform your response from a reactive to a proactive and in some cases to a preventive context enabling you minimum downtime and constant performance and throughput.</p>
                            <p>Sentinel deploys technologies of fortune 500 - 1000 companies</p>
                           
                           
                            {/* <a href="portfolio.html" className="btn float-btn flat-btn color-btn">My Portfolio</a> */}
                        </div>
                    </div>
                    <div className="col-md-12">
                    <div  id="sec1" data-scrollax-parent="true">
            <div className="slider-carousel-wrap full-height fullscreen-slider-wrap" >
                <div  className="fullscreen-slider full-height cur_carousel-slider-container fl-wrap" data-slick='{"autoplay": true, "autoplaySpeed": 4000 , "pauseOnHover": false}'>
                {slides.map(slide => (
                    <div key={slide.image} className="fullscreen-slider-item full-height fl-wrap">
                        <div className="bg par-elem hero-bg" style={{ display: 'flex',alignItems:'center',justifyContent:'center' }}><img src={slide.image} style={{ objectFit: 'contain',width:'800px' }}/></div>
                      
                        <div className="half-hero-wrap">
                          
                          
                           
                            {/* <a href={slide.action.link} className="custom-scroll-link btn float-btn flat-btn color-btn mar-top">Let's Start</a> */}
                        </div>
                    </div>
                ))}
                </div>
                <div className="sp-cont   sp-cont-prev"><i className="fal fa-arrow-left"></i></div>
                <div className="sp-cont   sp-cont-next"><i className="fal fa-arrow-right"></i></div>
                <div className="fullscreenslider-counter"></div>
            </div>
           
               </div>
                    </div>
                   
                    
                </div>
                
            </div>

           

            <div className="sec-lines"></div>
        </section>
        

        </div>
        
    )
}

export default HomeAbout
