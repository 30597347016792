import React from 'react'
import NumbersBgImage from '../images/bg/bg-stats.jpg'

const HomeStats = () => {

    return (
        <section style={{ paddingTop: '0px' }} id="highlights" data-scrollax-parent="true">
        <div className="section-subtitle"  data-scrollax="properties: { translateY: '-250px' }"><span>//</span>Highlights</div>
        <div className="container">
            <div className="section-title fl-wrap">
                <h3>HIGHLIGHTS</h3>
                <h2> Feature <span>Highlights</span></h2>
            </div>
            <div className="process-wrap fl-wrap">
                <ul>
                    <li>
                        <div className="time-line-icon">
                            <i className="fab pragicts-integrate-icon"></i>
                        </div>
                        <h4>Integrate All</h4>
                        <div className="process-details">
                            {/* <h6>Duis autem vel eum iriure dolor</h6> */}
                            <p>Hardware, Software, Machinery, Cloud, Sensors, etc.</p>
                            {/* <a href="#">More Details</a> */}
                        </div>
                        <span className="process-numder">01.</span>
                    </li>
                    <li>
                        <div className="time-line-icon">
                            <i className="fal pragicts-alert-icon"></i>
                        </div>
                        <h4>Instant Alerts</h4>
                        <div className="process-details">
                            {/* <h6>In ut odio libero, at vulputate urna. </h6> */}
                            <p>Define the alters as per one's need including on thresholds and parameters</p>                            {/* <a href="#">More Details</a> */}
                        </div>
                        <span className="process-numder">02.</span>
                    </li>
                    <li>
                        <div className="time-line-icon">
                            <i className="fal pragicts-extensible-icon"></i>
                        </div>
                        <h4>Extensible</h4>
                        <div className="process-details">
                            {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                                    <p>To integrate into any infrastructure</p>
                            {/* <a href="#">More Details</a> */}
                        </div>
                        <span className="process-numder">03.</span>
                    </li>
                    <li>
                        <div className="time-line-icon">
                            <i className="fal pragicts-client-icon"></i>
                        </div>
                        <h4>Global Client Portfolio</h4>
                        <div className="process-details">
                            {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                           <p>Fortune 1000 companies including (Amadeus, Docker, Ericsson, Microsoft, Salesforce, CISCO, SIEMENS, DELL, Tripadvisor, JPMorgan, Chase&Co, redis, ebay, Bloomberg, Verizon, Dropbox, Deutsche, Telekom, Argus, Eurotech, etc. uses same technology stack which is used by Sentinel</p>
                            {/* <a href="#">More Details</a> */}
                        </div>
                        <span className="process-numder">04.</span>
                    </li>
                    <li>
                        <div className="time-line-icon">
                            <i className="fal pragicts-secure-icon"></i>
                        </div>
                        <h4>Secure</h4>
                        <div className="process-details">
                            {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                           <p>Decoupled layers</p>
                            {/* <a href="#">More Details</a> */}
                        </div>
                        <span className="process-numder">05.</span>
                    </li>
                    <li>
                        <div className="time-line-icon">
                            <i className="fal pragicts-responsive-icon"></i>
                        </div>
                        <h4>Web Browser Based</h4>
                        <div className="process-details">
                            {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                           <p>Responsive Web browser-based dashboard</p>
                            {/* <a href="#">More Details</a> */}
                        </div>
                        <span className="process-numder">06.</span>
                    </li>
                    <li>
                        <div className="time-line-icon">
                            <i className="fal pragicts-open-icon"></i>
                        </div>
                        <h4>Platform Neutral</h4>
                        <div className="process-details">
                            {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                           <p>On-premise, Cloud, Windows, Linux, etc</p>
                            {/* <a href="#">More Details</a> */}
                        </div>
                        <span className="process-numder">07.</span>
                    </li>
                    <li>
                        <div className="time-line-icon">
                            <i className="fal pragicts-custamize-icon"></i>
                        </div>
                        <h4>Customizable</h4>
                        <div className="process-details">
                            {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                           <p>The dashboard is customizable to meet the specific needs of a customer</p>
                            {/* <a href="#">More Details</a> */}
                        </div>
                        <span className="process-numder">07.</span>
                    </li>
                    <li>
                        <div className="time-line-icon">
                            <i className="fal pragicts-dashboard-icon"></i>
                        </div>
                        <h4>Live Dashboard</h4>
                        <div className="process-details">
                            {/* <h6>Nulla posuere sapien vitae lectus suscipit</h6> */}
                           <p>Live GUI dashboard for effective monitoring and actioning.</p>
                            {/* <a href="#">More Details</a> */}
                        </div>
                        <span className="process-numder">07.</span>
                    </li>

                
                    
                </ul>
            </div>
            {/* <div className="fl-wrap mar-top">
                <div className="srv-link-text">
                    <h4>Need more info ? Visit my services page :  </h4>
                    <a href="services.html" className="btn float-btn flat-btn color-btn">My Services</a>
                </div>
            </div> */}
        </div>
        <div className="bg-parallax-module" data-position-top="90"  data-position-left="30" data-scrollax="properties: { translateY: '-150px' }"></div>
        <div className="bg-parallax-module" data-position-top="80"  data-position-left="80" data-scrollax="properties: { translateY: '350px' }"></div>
        <div className="sec-lines">
            <div className="container full-height"><div className="line-item"></div><div className="line-item"></div><div className="line-item"></div><div className="line-item"></div><div className="line-item"></div></div>
        </div>
    </section>
    )
}

export default HomeStats
